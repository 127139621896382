import {Box, Card, Typography} from "@mui/material";
import profile from '../images/1000002796.jpg';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import FacebookIcon from '@mui/icons-material/Facebook';
const Contact = () => {
    return (
        <Card id={'contact'} className={'contactCard'}>
            <Box sx={{ marginTop: '20px', marginBottom: '20px', width:'35%'}}>
                <img className={'contactImg'} src={profile} alt={'tapaszkalauz'}/>
            </Box>
            <Box sx={{color: 'black', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '35%'}}>
                <Typography variant="h5" sx={{marginTop: '20px', fontWeight: 'bold'}}>Kapcsolat</Typography>
                <Typography variant="body1" sx={{fontSize: '20px', fontWeight: 'bold'}}>Németh Krisztina Super Patch független munkatárs</Typography>
                <Typography variant="body1" sx={{fontSize: '20px', fontWeight: 'bold'}}><PhoneIphoneIcon/> Telefon: +36 30 238 1951</Typography>
                <Typography variant="body1" sx={{fontSize: '20px', fontWeight: 'bold'}}>Pető Zoltán Super Patch független munkatárs</Typography>
                <Typography variant="body1" sx={{fontSize: '20px', fontWeight: 'bold'}}><PhoneIphoneIcon/> Telefon: +36 70 617 3131</Typography>
                <Typography variant="body1" sx={{fontSize: '20px', fontWeight: 'bold'}}><MailIcon/> Email: tapaszkalauz@gmail.com</Typography>
                <Typography variant="body1" sx={{fontSize: '20px', fontWeight: 'bold'}}><a href="https://www.facebook.com/groups/218323775302649" target="_blank" rel="noreferrer"> <FacebookIcon sx={{fontSize: '40px', color: 'blue'}}/></a> Tapaszkalauz
                    facebook csoport</Typography>
            </Box>
        </Card>
    )
}

export default Contact;