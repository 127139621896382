import {Box, Card, CardMedia, Typography} from "@mui/material";
import tapasz from "../tapasz.mp4";
import Inventor from "./Inventor";

const Details = () => {
    return (
        <Box id={'details'}>
            <Card sx={{width: '90%', margin: 'auto',background: 'linear-gradient(to left, #EDFFFF, white)'}}>
            <Typography variant="h5" sx={{padding: '10px', color: '#08499C'}} >A Szuper Tapaszok működése a gyógyszermentes Vibrotaktilis Technológián alapul</Typography>
            <Typography variant="body1" sx={{padding: '10px', color: '#08499C', fontWeight: 'bold'}} >Mi a Vibrotaktilis Technológia? És hogyan működik?</Typography>
            <Typography variant="subtitle1" sx={{color: '#08499C', padding: '10px'}}>Vibrációs érintéses kioldó technológiának, VTT-nek nevezzük.
                A ( Vibrotactile Tigger Technology ) 12 éves fejlesztés, innováció eredménye.
                Egyedülálló a világpiacon, semmilyen konkurenciája nincs!
                <strong>A SUPER PATCH TAPASZ NEM INVAZÍV, SEMMILYEN KEMIKÁLIÁT, SEMMILYEN HATÓANYAGOT NEM TARTALMAZ!</strong> 100%-ban összetevő és kockázatmentes!
                Gyerekeknél és terhes anyáknál is alkalmazható.
                FDA engedéllyel rendelkezik!
                ( Az FDA ( Food and Drug Administration ) egy olyan amerikai szervezet, aminek jóváhagyása nélkül semmilyen élelmiszer vagy gyógyszer nem kerülhet forgalomba. Innen a nevük, Amerikai Élelmiszer és Gyógyszer engedélyeztetési Hivatal. )
                A Super Patch felületén lévő ( Nano technologiával felvitt. ) technológia egyedülállóan elrendezett mintázattal, barázdákkal rendelkezik, amelyek úgy néznek ki, mint egy „QR-kód”.
                Amikor ezek a mintázatók, barázdák megérintik a bőrünket, a sejtjeink egyedi jeleket továbbítanak a bőr receptorain, idegvégződésein keresztül. Tehát kölcsönhatásba lépnek a testünk idegrendszerével.
                Amint a Super Patch tapasz érintkezik a bőrünkkel, akkor olyan idegi választ vált ki az agytörzsben, amely segít a mindennapi problémáinkkal szembenézni.
                A Super Patch tapaszok az életünk folyamán felmerülő kihívások széles skáláján segíthetnek.
                A stressztől a rossz alvástól kezdve a fáradságon át a krónikus fájdalomig.
                A mozgás felszabadulásától kezdve a mentális problémákig, további számos területen fejtik ki áldásos hatásukat.
                Például az erő növelésével és a koncentráció javításával a sportteljesítményt is nagymértékben növelhetjük!
                Minden fizikai, érzelmi és pszichológiai funkciónak van egy neurokódja.
                Ha ezek a neurokódok bármilyen okból sérülnek vagy elakadnak, romlott egészséghez, rossz közérzethez és alacsony szintű teljesítményhez vezetnek.
                A Super Patch tapaszok megjavítják a neurokódokat és a testünket, elménket a legmagasabb szintű teljesítményre késztetik. A Super Patch tapaszok fajtáit folyamatosan fejlesztik!
                Jelenleg 12 féle tapasz van forgalomban, melyeknek fajtánként egyedi VTT „QR” kódja van. A tapaszok rendkívül gyorsan kifejtik hatásukat. Ahogyan a bőrrel érintkeznek azonnal! ( Ezért az emberek szívesen tovább ajánlják a környezetüknek, a kapcsolataiknak! )
                A Super Patch technológia tudományosan igazolt.
                Hatóanyag mentes, nem invazív, kockázatmentes, megfizethető és könnyen használható. 30 napos pénzvisszafizetési garancia van a Super Patch tapaszokra!
                Nincsenek összetevők! Tapaszaink gyógyszermentesek, gluténmentesek, parabénmentesek, latexmentesek, hipoallergének, GMO-mentesek és tanúsított bőrbiztonságosak.
                Tapaszaink bőrgyógyászatilag jóváhagyottak, klinikailag teszteltek és tudományos alapokon nyugszanak!
                Képzelje el, hogy szinte bárkinek segíthet az egész világon, ha tovább ajánlja a Super Patch tapaszokat!
                A tapasztalatok alapján a legtöbb embernek szüksége van valamelyik, vagy akár több fajta Super Patch tapaszra!
                Ha kipróbálnád, kérdéseddel, rendeléssel keress bátran!
            </Typography>
            </Card>
            <br/>
            <Card className={'detailsVideo'} sx={{display: 'flex', justifyContent: 'space-around', width: '90%', margin: 'auto',background: 'linear-gradient(to left, #EDFFFF, white)'}}>
                <CardMedia
                    sx={{width: '45%', marginTop: '20px', marginBottom: '20px'}}
                    controls
                    component="video"
                    src={tapasz}
                    title="Tapasz"
                />
                <CardMedia
                    sx={{width: '45%', marginTop: '20px', marginBottom: '20px'}}
                    controls
                    component="iframe"
                    src={'https://www.youtube.com/embed/iqybW602GY4'}
                    title="Tapasz"
                />
            </Card>
            <Inventor/>
        </Box>
    );
}

export default Details;