import './App.css';
import {Box} from "@mui/material";
import Welcome from "./components/Welcome";
import Header from "./components/Header";
import Contact from "./components/Contact";
import Details from "./components/Details";
import Products from "./components/Products";
import Footer from "./components/Footer";

function App() {
  return (
    <Box className={"container"} sx={{width: '100%'}}>
        <Header/>
        <Welcome/>
        <Contact/>
        <Details/>
        <Products/>
        <Footer/>
    </Box>
  );
}

export default App;
