import {Box, Card} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailIcon from "@mui/icons-material/Mail";
import logo from "../images/logo.jpg";

const Footer = () => {
    return (
        <footer>
            <Card className={'footerBox'} sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                <Box sx={{textAlign: 'center',fontWeight: 'bold'}}>
                    <a href="https://www.facebook.com/groups/218323775302649" target="_blank" rel="noreferrer">
                        <FacebookIcon sx={{fontSize: '40px', color: 'blue'}}/></a>
                    <br/>
                    <a href="https://www.facebook.com/groups/218323775302649" target="_blank" rel="noreferrer">Tapaszkalauz
                        facebook csoport</a>
                </Box>
                <Box sx={{textAlign: 'center',fontWeight: 'bold'}}>
                    <MailIcon/> <span> tapaszkalauz@gmail.com</span>
                    <p>Keressen E-mail címünkön, és 24 órán belül felvesszük önnel a kapcsolatot</p>
                </Box>
                <Box>
                    <img className={"footerLogo"} style={{marginTop: '20px', marginBottom: '22px'}} src={logo}
                         alt={"super patch"}/>
                </Box>
            </Card>
        </footer>
    );
}

export default Footer;