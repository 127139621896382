import {Box, Card} from "@mui/material";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { Link } from 'react-scroll';


const Header = () => {
    const styles = {
        card: {
            color: 'red',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
                ['@media (max-width:600px)']: { // mobil nézet stílusai
        flexDirection: 'column',
            height: '70px',
            alignItems: 'center',
            justifyContent: 'space-between',
    }
        },
        box: {
                width: '50%',
                fontSize: '20px',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'space-around',
            ['@media (max-width:600px)']: { // mobil nézet stílusai
                width: '100%',
                justifyContent: 'space-between',
                fontSize: '15px',
            }
            },
        }
    return (
        <Card sx={styles.card}>
            <Box sx={{
                fontSize: '20px',
            }}>
                <span style={{fontWeight: "bold"}}> <PhoneIphoneIcon/>+36302381951</span>
            </Box>
            <Box sx={styles.box}>
                <Link to="contact" smooth={true} duration={500}><span className={'headSpan'}>Kapcsolat</span></Link>
                <Link to="details" smooth={true} duration={500}><span className={'headSpan'}>Részletes leírás</span></Link>
                <Link to="inventor" smooth={true} duration={500}><span className={'headSpan'}>A feltalálóról</span></Link>
                <Link to="products" smooth={true} duration={500}><span className={'headSpan'}>Termékek</span></Link>
            </Box>
        </Card>
    );
}

export default Header;