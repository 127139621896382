import liberty from '../images/Liberty.webp';
import defend from '../images/Defend.webp';
import peace from '../images/Peace.png';
import focus from '../images/Focus.webp';
import victory from '../images/Victory.jpg';
import freedom from '../images/Freedom.webp';
import lumi from '../images/Lumi.webp';
import ignite from '../images/Ignite.webp';
import joy from '../images/Joy.webp';
import rem from '../images/Rem.webp';
import kick from '../images/KICK IT.webp';
import rocket from '../images/rocket.webp';
import {Box, Card, Typography} from "@mui/material";

const Products = () => {
    const products = [
        {
            name: 'LIBERTY tapasz – érj el jobb egyensúlyt és stabilitást!',
            image: liberty,
            details: 'A mozgásunk javítása és az egyensúly érzék az egészséges életmód és a mindennapi tevékenység sarokkövei. Legyen szó lépcsőzésről, gyaloglásról, futásról, munkáról vagy bármilyen sportról, a Liberty Patch egy non-invazív és gyógyszermentes technológia, amely elősegítheti a jobb egyensúlyt és stabilitást, az egészséges jólét megőrzésének kulcsfontosságú elemeit. Alap tapasz, mindegyik tapasz hatékonyságát növelheti ha a Liberty tapasszal együtt alkalmazzák. Idegrendszeri zavarok – koordinációs problémák, járásbizonytalanság esetén kötelező! Gyulladáscsökkentés és szédülés enyhítésére is ajánljuk.  Sérülések , műtétek után vagy nehéz rehabilitáció és gyógyulás után gyakori, hogy elszokunk a mozgástól, azonban a mozgás hiánya számos egészségügyi problémához vezethet. Szerencsére a Liberty Super tapaszok 100%-ban gyógyszermentes, természetes és könnyen használható megoldást nyújthatnak, hogy nagyobb egyensúllyal, erővel és stabilitással mozoghassunk.'
        },
        {
            name: 'DEFEND tapasz – Védelem', image: defend, details: `A mindennapok felszíne alatt rejtőzködve egy meg nem nevezett hős védi fáradhatatlanul a jólétünket: az immunrendszerünk. Ez a sejtek és molekulák összetett hálózata a szervezet első védelmi vonalaként szolgál a betolakodókkal szemben, a vírusoktól és baktériumoktól kezdve a káros anyagokig. Az erős immunrendszer alapvető tényezője az általános egészségnek. A Defend Patch egy nem-invazív és gyógyszermentes technológia, amely vibrotaktikus stimuláció révén támogathatja az immunrendszert, és így megkönnyítheti az általános egészség fenntartását. Immunerősítés, Jobb keringésre, gyógyulás felgyorsítására, allergia , asztma enyhítésére, sejtek oxigénellátásának javítására, vírusok, fertőzések leküzdésére ajánljuk. A Defend Super Patch tapaszok természetes megoldást kínálnahatnak immunrendszere egészségének megőrzésére, mellékhatások nélkül!`
        },
        {
            name: 'PEACE tapasz – Belső béke',
            image: peace,
            details: 'Belső béke egyszerű és természetes módon Az embereknek több, mint 70%-a él olyan stresszben, ami nyomot hagy az érzelmi, mentális és testi egészségükön. A férfiak és nők majdnem 50%-a stressz alapú alvászavarral küszködik. Sajnálatos módon az egyik legnépszerűbb „megoldás“ az antidepresszánsok szedése, amelyek lehet, hogy kifejezetten hatékonyak, ám függővé tesznek és veszélyesek, mert leválaszthatnak az érzelmi világunktól. A Peace Super Patch egy információ alapú, kémiai anyagoktól 100%-osan mentes, természetes és egyszerűen használható megoldást kínál a stressz oldására. A Super Patch technológia segítségével visszatalálhatunk a belső békénkhez, veszélyes mellékhatások nélkül.'
        },
        {
            name: 'FOCUS tapasz – Fókuszban a fókusz!',
            image: focus,
            details: 'A Focus Super Patch egy információ alapú, kémiai anyagoktól 100%-osan mentes, természetes és egyszerűen használható megoldást kínál a diákoknak, szellemi dolgozóknak, sofőröknek ahhoz, hogy növeljék a figyelemképességük időtartamát, hogy kisebb mértékű stresszben éljenek és tudjanak koncentrálni.'
        },
        {
            name: 'VICTORY tapasz – Természetesen Győzelem!',
            image: victory,
            details: 'Csúcsteljesítmény életünk mérkőzésein? Természetesen! Ahhoz, hogy a legjavunkat mozgósítsuk, el kell hinnünk, hogy érzelmileg, mentálisan és testileg is képesek vagyunk határaink átlépésére. Az információ alapú Victory Super Patch a sportteljesítmény fokozására lett kifejlesztve, hogy legmagasabb szinten, kirobbanó, erőteljes és fókuszált állapotban lehessünk életünk minden mérkőzésén. Tanulmányok kimutatták, hogy a Victory Patch 22%-kal emeli az átlagos teljesítményt, 31%-kal fokozza az egyensúlyt és a stabilitást, valamint 18%-kal javítja az erőkifejtés mértékét a nagyobb sebesség és a dinamika javára. Segítséget nyújthat görcsölő, fájó, feszülő izmok ellazításában és az izmfájdalmak csillapításában. Használatával elkerülhető az izomláz.'
        },
        {
            name: 'FREEDOM tapasz – Felszabadulás az izom- és ízületi fájdalmak alól',
            image: freedom,
            details: 'Fájdalomcsillapítás egyszerű és biztonságos módon A krónikus fájdalmak mind testileg, mind érzelmileg befolyásolják az életminőségünket. A Freedom Super Patch egy információ alapú, kémiai anyagoktól 100%-osan mentes, természetes és egyszerűen használható megoldást kínál. Tanulmányok igazolják, hogy az általunk alkalmazott vibrotaktilis technológia jelentősen csökkentette a krónikus fájdalmakat a résztvevő tesztalanyok 95%-ánál mindössze egyetlen hét alatt.'
        },
        {
            name: 'LUMI tapasz – segítség a bőrápolási rutinban',
            image: lumi,
            details: 'Az egészséges és ragyogó arcszín elérése gyakran célja azoknak, akik egy kiegyensúlyozott életmódot keresnek. A Lumi Patch egy nem invazív és gyógyszermentes technológia, amely segíthet a bőrápolási rutinjában, ha azt kiegyensúlyozott étrenddel, rendszeres testmozgással, megfelelő hidratálással és elegendő pihenéssel kombinálja.'
        },
        {
            name: 'IGNITE tapasz – Zsírégetés',
            image: ignite,
            details: 'Anyagcsere egyensúly – A kanapén ülve is intenzíven éghet a zsír! Anyagcserénk nyugalmi állapotban is optimalizálható. Az RMR (Resting Metabolic Rate – Nyugalmi Anyagcsere Arány) megmutatja, hogy mennyi kalóriát égetünk el nyugalmi állapotban, alvás, vagy munka közben, vagy akár a kanapén ülve. Az egészséges testsúly fenntartásához fontos a bevitt és elégetett kalóriák egyensúlyban tartása. Az RMR az információ alapú Ignite Super Patch segítségéval akár 25%-kal fokozható az elégetett kalóriák aránya! Ez azt jelenti, hogy még fizikai aktivitás hiányában is 25%-kal több kalóriát égethetünk el. Fokozzuk az anyagcserénket 100%-ban gyógyszermentes megoldással! Támogathatja a májműködést. Alkalmazási területei: méregtelenítés támogatása testsúly harmonizálás anyagcsere-zavarok és abból adódó egészségügyi problémák kiegyensúlyozása túlsúly és abból fakadó egészségügyi problémák oldása.'
        },
        {
            name: 'JOY tapasz – Öröm',
            image: joy,
            details: 'Érzelmi jólét és kiegyensúlyozottság. A remény, az önmagunkba vetett hit és a boldogság talán az emberlét három legfontosabb aspektusa. Soha nem volt könnyebb megtalálni ezt az örömöt és optimizmust az életünkben! A Joy Super Patch egy non invazív és gyógyszermentes technológia, amely a vibrotaktilis stimuláció révén javíthatja a jólét és a boldogság érzését.'
        },
        {
            name: 'REM – Egészséges Alvás',
            image: rem,
            details: 'Mély és regeneráló alvás. Egyszerű és biztonságos módon támogathatja a kiegyensúlyozott alvási ciklust. Levendula, CBD, Melatonin… Alváshoz segítő dallamok… Báránykák számolása… Podcast hallgatása… Már mindent kipróbáltunk, és még mindig csak álom a gyors elalvás és mély alvás? A REM Patch segítségével mélyebben lehet aludni, mellékhatások nélkül.'
        },
        {
            name: 'KICK IT Tapasz – Függőségek oldása',
            image: kick,
            details: 'Érzelmi jólét és kiegyensúlyozottság A rossz szokások, a függőségek, ronthatják az életminőséget. Ezek a szokások általában a szándékok, a gondolatok és az ismétlődő cselekvések összetett hálójában gyökereznek. Törjünk ki az életünkbe berögződött negatív szokásaink alól, és helyére pozitív, egészségmegőrző rituálékat engedhetünk. A Kick It Super Patch egy forradalmian új gyógyszermentes megoldás, amellyel a vibrotaktilis stimuláció révén javíthatjuk a jólétünket és  visszavehetjük az irányítást az életünk és szokásaink felett.'
        },
        {
            name: 'ROCKET tapasz – a teljesítmény optimalizálása',
            image: rocket,
            details: 'A teljesítmény optimalizálása és az elégedettség elérése a jólét alapkövei. A Rocket Patch egy gyógyszermentes és nem invazív megoldás, amely, ha beépül egy egészséges rutinba, képes lehet fokozni a teljesítményt és növelni az általános jóléttel kapcsolatos elégedettséget. Használata növelheti a férfi erőt és potenciát, hölgyeknél a libidót.'
        }
    ];
    return (
        <Box id={'products'}>
            {products.map((product, index) => (
                <Box key={index} sx={{padding: '20px'}}>
                    <Card className={'products'} sx={{display: 'flex', justifyContent: 'space-around'}}>
                    <Box sx={{width: '20%', textAlign:'center'}}>
                        <img src={product.image} alt={product.name} style={{height: '300px'}}/>
                    </Box>
                    <Box sx={{width: '60%'}}>
                        <Typography variant="h5" sx={{color: '#08499C', fontWeight: 'bold'}}>{product.name}</Typography>
                        <Typography variant="body1"
                                    sx={{textAlign: 'left', fontSize: '20px'}}>{product.details}</Typography>
                    </Box>
                    </Card>
                </Box>
            ))}
        </Box>
    );
}

export default Products;