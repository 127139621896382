import {Box, Card, CardMedia, Typography} from "@mui/material";
import {useState} from "react";
import jay1 from '../images/Jay-Dhaliwal-CEO-2024-The-Super-Patch-Company-.webp';
import brain from '../images/Agy 4.jpg';
import jay2 from '../images/JAY 5.jpg';
import tapasz from '../images/Tapaszok 6.jpg';


const Inventor = () => {
    const [open, setOpen] = useState(false);
    return (
        <Box sx={{padding: '15px'}} id={'inventor'}>
            <Typography variant="h5" sx={{color: '#08499C'}} >A feltalálóról: <span style={{cursor: 'pointer', fontWeight: 'bold', color: '#08499C', fontSize:'35px', padding:'20px'}} onClick={()=>{setOpen(!open)}}>Bővebben <img width={'5%'} src={jay1} alt={'spatch tapasz'}/> </span></Typography>
            {open &&
                <Card sx={{padding: '15px', marginTop: '10px', background: 'linear-gradient(to left, #50C5FF, white)'}}>
                <Typography variant="subtitle1" sx={{textAlign: 'left',fontSize: '20px'}}>Mielőtt bárki hinne neked, hinned kell magadban
                Jay Dhaliwalnek, a Super Patch feltalálójának, meghatározó tinédzser kora volt, mivel 13 éves volt mikor édesanyjánál szklerózis multiplex betegséget diagnosztizáltak. Innentől fogva Ő gondoskodott róla és két testvéréről is. A szeretet, a segíteni akarás akkora erőt adott számára, hogy keresse és megtalálja a megoldást a betegségre.
                Foglalkozása révén mérnök és programozó volt, de szerencséjére neves klinikákon a legjobb kutatókkal dolgozhatott együtt. Nem sajnálta sem a pénzt, sem az időt, éveken át tanulmányozta, miként működik az agy.
                De nem szeretnélek untatni Benneteket a kutatási fázisokkal és eredményekkel, viszont, hogy könnyebben megértsd, mi is történik a Super Patch használatakor, képzeld el a következőt:
                Csukd be a szemed… valaki egy számot rajzol a karodra, vagy a hátadra…. érzed  De még mindig csukva a szemed….kinyújtod a tenyered, beleteszek egy tollat, vagy egy telefont…..és ezt érzed-e  Tudod-e, hogy mit tettem tenyeredbe  Hát persze, hogy érezzük és tudjuk, hiszen a bőrünkön keresztül a sejtjeink egyedi jeleket továbbítanak, amelyek kölcsönhatásba lépnek testünk idegrendszerével. Hát valahogy így működik a tapasz is. A tapaszokkal az agyad összefüggésbe kerül.
                A tapasz a jobb fájdalomcsillapítás, jobb testtartási stabilitás és egyensúly, valamint fokozott mobilitás révén segíti az ember egészségét és jólétét.

                Jay szavait idézem:
                „Legyen szó egészségről vagy üzletről, amikor valaki személyes felelősséget vállal azért, hogy szembeszálljon a kihívással, ez az emberi szellem áll minden felfedezés, minden haladás és minden siker mögött. Amikor látom, hogy megnyilvánul, eszembe jut, hogy bármit is szeretnék látni az életben vagy az üzleti életben, az velem kezdődik.”</Typography>
                    <Card className={'inventorCard'} sx={{display: 'flex', justifyContent: 'space-around', marginTop: '20px', width: '80%', background: 'linear-gradient(to left, #EDFFFF, white)'}}>
                     <Box sx={{width: '40%'}}>
                    <CardMedia
                    component={'img'}
                    src={jay1}
                    alt={'jay'}
                    sx={{width: '55%', margin: 'auto'}}
                    />
                     </Box>
<Typography variant="subtitle1" sx={{textAlign: 'left',fontSize: '20px', width: '35%'}}>
                A Szupertapasz nem tartalmaz gyógyszert.
                Kutatási és klinikai eredmények által támogatott.
                Nincs mellékhatás.
    Teljesen biztonságos, mert nincs benne hatóanyagtartalom csak a barázda mintázat van rajta, ami különféle kódokat tartalmaz ami a bőrön keresztül olyan jeleket generál, ami létezik az agyunkban, csak megerősíti azokat. Az agy értelmez, feldolgoz és helyreállítja a sérült elakadt hálózatokat.</Typography>
                    </Card>
                    <Card className={'inventorCard'} sx={{display: 'flex', justifyContent: 'space-around', marginTop: '20px', width: '80%', background: 'linear-gradient(to left, #EDFFFF, white)'}}>
                        <Box sx={{width: '40%'}}>
                <CardMedia
                component={'img'}
                src={brain}
                alt={'brain'}
                sx={{width: '100%', marginTop: '20px', marginBottom: '20px'}}
                />
                        </Box>
<Typography variant="subtitle1" sx={{textAlign: 'left',fontSize: '20px', width: '40%'}}>
                Ez a stimulációs technológia 30-50 évvel előbre jár a korunkhoz képest.
                Hogyan és miért működik?
                Minden folyamatnak a testünkben van egy neurológiai kódja az agyunkban.
                Ezek a hullamok időnként elakadnak, megsérülnek.
                Minden tapasznak van egy összetett mintázata.
                Bőrünkkel érintkezve a receptorokkal, ioncsatornákkal potenciákat generálnak, specifikus jelet alkotnak, amit az agyunkba küld, és az elakadt hálózatok újra működni fognak, kijavítódnak, helyre álnak.
                Az elme és a test újra összhangba kerül.
                Amit küldünk az agynak felismeri és azonnal működik.
                Ezt a technológiát VTT-nek (Vibrotactill Trigger Technology) vibrotactilis ingerlési technológiának nevezzük.
</Typography>
                    </Card>

                    <Card className={'inventorCard'} sx={{display: 'flex', justifyContent: 'space-around', marginTop: '20px', width: '80%', background: 'linear-gradient(to left, #EDFFFF, white)'}}>
                        <Box sx={{width: '45%'}}>
                <CardMedia
                component={'img'}
                src={jay2}
                alt={'jay'}
                sx={{width: '100%', padding: '20px'}}
                />
                        </Box>
                    <Typography variant="subtitle1" sx={{textAlign: 'left',fontSize: '20px', width: '40%'}}>
                    A Szuper tapasz egy fantasztikus egyedi,új hatékony technológia a fájdalommentes, egészségesebb és boldogabb élethez az egész családnak.
                12 féle tapasz számos egészségügyi problémára nyújthat megoldást.
                A világ első és egyetlen szupertapasz készítménye.
                Hosszú kutatás, 13 év eredményeképpen ez az egyedülálló neurostimulációs technológia, ami a bőrön keresztül hat a szervezeten és a tapaszon lévő mintázat (neurológiai kód) segítségével bekapcsolja a szervezet öngyógyító képességét.
                Kicsit hihetetlennek tűnik de működik.
                Elképesztő eredmények vannak klinikai vizsgálatokról, és rengeteg jó tapasztalat.
                Tarts velem ebben a hihetetlennek tűnő öngyógyításban.
                Mindenkinek meg kell ismerni ezt a rendkívüli feltalálást!
            </Typography>
                    </Card>
                    <br/>
<CardMedia
    component={'img'}
    src={tapasz}
    alt={'tapasz'}
    sx={{width: '40%', marginTop: '20px', marginBottom: '20px', margin: 'auto'}}
    />
        </Card>}
        </Box>
    )
}

export default Inventor;