import {Box, Card, Typography} from "@mui/material";
import logo from "../images/logo.jpg";
import termekek from "../images/1000003163.jpg";

const Welcome = () => {
    return (
        <Box id={'welcome'} sx={{textAlign: 'center'}}>
            <Box className={'welcomeBox'} sx={{
                width: '50%',
                display: 'flex',
                justifyContent: 'space-around',
                margin: 'auto',
                alignItems: 'center'
            }}>
                <Typography variant="h4" sx={{color: 'red', marginTop: '20px', fontWeight: 'bold'}}>SZUPER PATCH
                    TAPASZKALAUZ</Typography>
                <img className={"welcomeImgLogo"} style={{marginTop: '20px', marginBottom: '22px'}} src={logo}
                     width={'100%'} alt={"super patch"}/>
            </Box>
            <img className={"welcomeImgProducts"} style={{marginTop: '20px', marginBottom: '22px'}} src={termekek}
                 alt={"tapasz"}/>
            <Typography variant="subtitle1" sx={{fontSize: '22px', color: '#08499C'}}>Super Patch tapasz: jobb közérzet
                természetes módon.</Typography>
            <hr style={{width: '50%', margin: 'auto', marginBottom: '20px'}}/>
            <Card className={'welcomeCard'}
                  sx={{width: '80%', margin: 'auto', padding: '20px', backgroundColor: '#f0f0f0'}}>
                <Typography variant="subtitle1"
                            sx={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: '#08499C'}}>Kedves
                    érdeklődő!</Typography>
                <Typography className={'welcomeText'} variant="body1" sx={{
                    textAlign: 'center',
                    fontSize: '20px',
                    marginLeft: '8%',
                    marginRight: '8%',
                    letterSpacing: '1px',
                    color: '#08499C'
                }}>
                    Ismerje meg segítségünkkel a Super Patch tapaszok működését,felhasználási területeit,és a
                    hatékonysághoz szükséges feltételeket! Feladatunknak tekintjük -szakemberek bevonásával- a termékek
                    használatához szükséges alapvető ismeretek átadását, melyek nélkül a tapaszok megvásárlása esetleg
                    kidobott pénz lenne. A nem megfelelő használatból eredő csalódás, elégedetlenség ártana cégünk jó
                    hírének, ezt szeretnénk személyreszóló tanácsainkkal megelőzni. Tudni kell, hogy ez a forradalmian
                    új technológia számtalan olyan embernek segített az életminősége javításában, akik már sok
                    hagyományosnak mondott módszerrel eredménytelenül próbálkoztak. A kisebb sérülések, mint például egy
                    izomhúzódás, akár egyetlen tapasz segítségével is orvosolható, de a hosszan kialakult problémák,
                    mint például a krónikus elhízás, évtizedek óta tartó izületi fájdalom,régóta fennálló keringési-,
                    emésztési zavar, csakis több hónapon át tart tapaszkombinációkkal kezelhetőek. Ehhez igyekszünk
                    Önnek segítséget nyújtani.
                </Typography>
            </Card>
        </Box>
    )
}

export default Welcome;